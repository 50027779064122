import React, { FC } from "react"
import { Link } from "gatsby"

const menuItems = [
  { label: "Hinnad", url: "/hinnad/" },
  { label: "FAQ", url: "/faq/" },
  { label: "Meist", url: "/meist/" },
  { label: "Juhendid", url: "/juhend/" },
]

interface MenuItemProps {
  label: string
  url: string
}

const MenuItem: FC<MenuItemProps> = props => {
  const pathName = typeof location !== "undefined" && location.pathname
  const isActive = pathName === props.url
  let className = "uppercase hover:underline mx-4 text-gray-700 font-bold"
  if (isActive) {
    className += " underline"
  } else {
    className += " hover:underline"
  }

  return (
    <div className={className}>
      <Link to={props.url}>{props.label}</Link>
    </div>
  )
}

const Logo = () => {
  return (
    <Link to="/">
      <div className="mx-10 cursor-pointer">
        <span className="text-black font-bold text-2xl">Firma</span>
        <span className="text-blue-800 text-2xl">Veeb.ee</span>
      </div>
    </Link>
  )
}

const Header = () => {
  return (
    <header className="p-4 flex justify-between items-center">
      <Logo />
      <div className="flex mx-6 flex-1">
        {menuItems.map(item => (
          <MenuItem label={item.label} url={item.url} key={item.url} />
        ))}
      </div>
      <div className="space-x-4 mx-10">
        <a
          className="rounded-md text-white bg-blue-800 px-4 py-2 hover:bg-blue-700 cursor-pointer"
          href="https://minu.firmaveeb.ee"
        >
          Logi sisse
        </a>
        <a className="text-blue-700 font-semibold cursor-pointer underline">
          <Link to="/proovi/">Proovi tasuta</Link>
        </a>
      </div>
    </header>
  )
}

export default Header
